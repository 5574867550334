<template>
  <section>
    <div class="bg-black">
      <div class="container">
        <h1>
          Our
          <span>Impact</span>
        </h1>
      </div>
    </div>
    <section class="container">
      <div class="highlight-reel flex__center">
        <Modal />
      </div>
    </section>
    <section class="pos__rel">
      <font-awesome-icon icon="heart" size="1x" class="bg-icon" />
      <div class="grid container">
        <p class="text__md para-one">
          To date the Flat Branch employees have raised over $1.8 million to help members of their community.
          We have used those funds to help over 320 individuals and families cover more than $490 thousand in medical expenses, financial shortcomings, and treatments.
          Over 130 organizations have received help meeting their financial needs, especially during the Covid-19 pandemic when fundraising events could not be held.
          Our partner United Way has also received over $200 thousand to impact communities in so many ways.
        </p>
        <p class="text__md para-two">
          Our scholarship program has awarded 17 scholarships to working adults seeking to acheive their dream of completing higher education and,
          most recently, we have partnered with Joe Machens CDJR to do a gift of reliable transportation to a qualifying individual in Missouri.
        </p>
        <div class="forms">
          <router-link
            to="/donation-request"
            name="Request financial assistance"
            class="btn btn__md btn__green"
          >Donation Request</router-link>
          <router-link
            to="/scholarship"
            name="Learn more about our scholarship"
            class="btn btn__md btn__green"
          >Scholarships</router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Modal from "./Modal";
export default {
  components: {
    Modal
  }
};
</script>

<style scoped lang="scss">
.bg-black {
  background-color: $black;
  padding: 50px 0;
  margin-bottom: 25px;
}
h1 {
  text-align: center;
  color: $powder;
  span {
    color: $green;
  }
}
.pos__rel {
  overflow: hidden;
}
.highlight-reel {
  height: 300px;
  width: 100%;
  margin: auto;
  margin-top: -50px;
  margin-bottom: 50px;
  max-width: 500px;
}
.grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
  grid-template-areas:
    "first"
    "second"
    "forms";
  margin-bottom: 75px;
}
.para-one {
  grid-area: first;
}
.para-two {
  grid-area: second;
}
.forms {
  grid-area: forms;
  @include grid($cols: 1fr, $row-gap: 15px);
  a {
    width: 100%;
    max-width: 300px;
    margin: auto;
  }
}
.bg-icon {
  color: $frost;
  @include pos-abs($pos: absolute, $width: 125%, $height: 125%, $z: -1);
  transform: rotate(-35deg) translateY(100px);
}
@media (min-width: $md) {
  .grid {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "first forms"
      "second second";
  }
  .forms {
    grid-template-rows: repeat(3, 65px);
    align-items: center;
  }
  .bg-icon {
    width: 175%;
    height: 175%;
    transform: rotate(-25deg) translateY(50px) translateX(-100px);
  }
}
@media (min-width: $lg) {
  h1 {
    text-align: left;
  }
  .highlight-reel {
    margin-top: -125px;
    margin-left: auto;
    margin-right: 0;
  }
}
@media (min-width: $xl) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
  }
}
</style>
